body {
    background: black;
  }
  
  .box {
    position: relative;
  }
  
  .box::before {
    content: "";
    width: 440px;
    height: 440px;
    background-color: #89cff0;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
  
  .box-body {
    position: relative;
    height: 200px;
    width: 200px;
    margin-top: 123.3333333333px;
    background-color: #cc231e;
    border-bottom-left-radius: 5%;
    border-bottom-right-radius: 5%;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3);
    background: linear-gradient(#762c2c, #ff0303);
  }
  
  .box-body .img {
    opacity: 0;
    transform: translateY(0%);
    transition: all 0.5s;
    margin: 0 auto;
    display: block;
  }
  
  .box-body-active {
    cursor: pointer;
    animation: box-body 1s forwards ease-in-out;
  }
  
  .box-body-active .img {
    opacity: 1;
    z-index: 0;
    transform: translateY(-157px);
  }
  
  .box-body-active .box-lid {
    animation: box-lid 1s forwards ease-in-out;
  }
  
  .box-body-active .box-bowtie::before {
    animation: box-bowtie-left 1.1s forwards ease-in-out;
  }
  
  .box-body-active .box-bowtie::after {
    animation: box-bowtie-right 1.1s forwards ease-in-out;
  }
  
  
  .box-body::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    background: linear-gradient(#ffffff, #ffefa0);
  }
  
  .box-lid {
    position: absolute;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    bottom: 90%;
    height: 40px;
    background-color: #cc231e;
    height: 40px;
    width: 220px;
    border-radius: 5%;
    box-shadow: 0 8px 4px -4px rgba(0, 0, 0, 0.3);
  }
  
  .box-lid::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    background: linear-gradient(#ffefa0, #fff);
  }
  
  .box-bowtie {
    z-index: 1;
    height: 100%;
  }
  
  .box-bowtie::before,
  .box-bowtie::after {
    content: "";
    width: 83.3333333333px;
    height: 83.3333333333px;
    border: 16.6666666667px solid white;
    border-radius: 50% 50% 0 50%;
    position: absolute;
    bottom: 99%;
    z-index: -1;
  }
  
  .center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .box-bowtie::before {
    left: 50%;
    transform: translateX(-100%) skew(10deg, 10deg);
  }
  
  .box-bowtie::after {
    left: 50%;
    transform: translateX(0%) rotate(90deg) skew(10deg, 10deg);
  }
  
  @keyframes box-lid {
    0%, 42% {
      transform: translate3d(-50%, 0%, 0) rotate(0deg);
    }
    60% {
      transform: translate3d(-85%, -230%, 0) rotate(-25deg);
    }
    90%, 100% {
      transform: translate3d(-119%, 225%, 0) rotate(-70deg);
    }
  }
  
  @keyframes box-body {
    0% {
      transform: translate3d(0%, 0%, 0) rotate(0deg);
    }
    25% {
      transform: translate3d(0%, 25%, 0) rotate(20deg);
    }
    50% {
      transform: translate3d(0%, -15%, 0) rotate(0deg);
    }
    70% {
      transform: translate3d(0%, 0%, 0) rotate(0deg);
    }
  }
  
  @keyframes box-bowtie-right {
    0%, 50%, 75% {
      transform: translateX(0%) rotate(90deg) skew(10deg, 10deg);
    }
    90%, 100% {
      transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
      box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.3);
    }
  }
  
  @keyframes box-bowtie-left {
    0% {
      transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
    }
    50%, 75% {
      transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
    }
    90%, 100% {
      transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
    }
  }
  